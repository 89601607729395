import React from 'react';
import {appUtil} from "../constant/appUtil";
import { useHistory } from "react-router-dom";
function ListItem({property}) {
  const history = useHistory();
  function onClick(){
    history.push("/property-View/"+property.id);
  }
  return (

  <tr onClick={() => onClick()}>
    <td><strong>{property.code}</strong></td>
    <td>{property.net_area} m<sup>2</sup></td>
    <td>{property.out_area} m<sup>2</sup></td>
    <td>{property.pool_area} m<sup>2</sup></td>
    <td>{property.roof_area} m<sup>2</sup></td>
    <td>{property.room_type.label}</td>
  </tr>

  );
}

export default ListItem;