import React, {useContext, useEffect, useState} from 'react';
import "../css/LightBox.css";
import LightBoxContext from "./context/LightBoxContext";

LightBox.propTypes = {

};

function LightBox(props) {
    const {lightBox,setLightBox} = useContext(LightBoxContext);
    const [imgLoaded,setImgLoaded] = useState(false);
    useEffect(() => {

        window.addEventListener('resize', handleResize)
        setTimeout(handleResize,100);

        var e = document.querySelector('body')
        var observer = new MutationObserver(function (event) {
            setTimeout(handleResize,100);
        })

        observer.observe(e, {
            attributes: true,
            attributeFilter: ['class'],
            childList: false,
            characterData: false
        })

    },[])

    useEffect(() => {
        (lightBox) ?  document.querySelector(".lightbox").classList.add("lightbox--opened") : document.querySelector(".lightbox").classList.remove(".lightbox--opened")
    },[lightBox])

    useEffect(() => {
        if(imgLoaded){
            handleResize()
        }
    },[imgLoaded])

    function handleResize() {
        let container = document.querySelector(".lightbox__image");
        if(container) {
            let img = container.querySelector("img");
            let imageAspectRatio = img.getBoundingClientRect().width / img.getBoundingClientRect().height;
            let isScreenLandscape = window.innerWidth >= window.innerHeight;

            if(isScreenLandscape)
            {
                img.style.width = "auto";
                img.style.height = container.getBoundingClientRect().height + "px";
                img.style.left = (container.getBoundingClientRect().width - container.getBoundingClientRect().height * imageAspectRatio)/2 + "px";

            }
            else{
                img.style.width = container.getBoundingClientRect().width + "px";
                img.style.height = "auto";
                img.style.top = (container.getBoundingClientRect().height - container.getBoundingClientRect().width * imageAspectRatio)/2 + "px";

            }
        }




    }

    function setCenter(container,img)
    {

    }
    return (
        <div className="lightbox">
            <button className="close-button colored" onClick={() => setLightBox(false)}>
                <div></div>
                <div></div>
            </button>
            <div className="lightbox__image-container">
                <div className="lightbox__image">
                    <img  src={props.media} onLoad={()=> setImgLoaded(true)} alt=""/>
                </div>
            </div>

        </div>
    );
}

export default LightBox;

