export const propertyConst = {
    slider : {
        "1-bed-apt" : [
            {source : require("../img/property_slider/1-bed-apt/i1.jpg")},
            {source : require("../img/property_slider/1-bed-apt/i2.jpg")},
            {source : require("../img/property_slider/1-bed-apt/i3.jpg")},
            {source : require("../img/property_slider/1-bed-apt/i4.jpg")},
            {source : require("../img/property_slider/1-bed-apt/i5.jpg")},
        ],
        "1-bed-apt_block_1" : [
            {source : require("../img/property_slider/1-bed-apt/block_1/i1.jpg")},
            {source : require("../img/property_slider/1-bed-apt/block_1/i2.jpg")},
        ],
        "1-bed-apt_block_2" : [
            {source : require("../img/property_slider/1-bed-apt/block_2/i1.jpg")},
            {source : require("../img/property_slider/1-bed-apt/block_2/i2.jpg")},
        ],
        "1-bed-apt_block_3" : [
            {source : require("../img/property_slider/1-bed-apt/block_3/i1.jpg")},
            {source : require("../img/property_slider/1-bed-apt/block_3/i2.jpg")},
        ],
        "1-bed-apt_block_4" : [
            {source : require("../img/property_slider/1-bed-apt/block_4/i1.jpg")},
            {source : require("../img/property_slider/1-bed-apt/block_4/i2.jpg")},
        ],
        "1-bed-apt_block_5" : [
            {source : require("../img/property_slider/1-bed-apt/block_5/i1.jpg")},
        ],
        "1-bed-apt_block_6" : [
            {source : require("../img/property_slider/1-bed-apt/block_6/i1.jpg")},
        ],
        "1-bed-apt_block_7" : [
            {source : require("../img/property_slider/1-bed-apt/block_7/i1.jpg")},
            {source : require("../img/property_slider/1-bed-apt/block_7/i2.jpg")},
        ],
        "1-bed-apt_block_8" : [
            {source : require("../img/property_slider/1-bed-apt/block_8/i1.jpg")},
            {source : require("../img/property_slider/1-bed-apt/block_8/i2.jpg")},
        ],
        "1-bed-apt_block_11" : [
            {source : require("../img/property_slider/1-bed-apt/block_11/i1.jpg")},
            {source : require("../img/property_slider/1-bed-apt/block_11/i2.jpg")},
        ],
        "1-bed-apt_block_15" : [
            {source : require("../img/property_slider/1-bed-apt/block_15/i1.jpg")},
            {source : require("../img/property_slider/1-bed-apt/block_15/i2.jpg")},
        ],
        "1-bed-smart" : [
            {source : require("../img/property_slider/1-bed-smart/i1.jpg")},
            {source : require("../img/property_slider/1-bed-smart/i2.jpg")},
            {source : require("../img/property_slider/1-bed-smart/i3.jpg")},
            {source : require("../img/property_slider/1-bed-smart/i4.jpg")},
            {source : require("../img/property_slider/1-bed-smart/i5.jpg")},
            {source : require("../img/property_slider/1-bed-smart/i6.jpg")},
        ],
        "1-bed-smart-special" : [
            {source : require("../img/property_slider/1-bed-smart-special/i1.jpg")},
            {source : require("../img/property_slider/1-bed-smart-special/i2.jpg")},
            {source : require("../img/property_slider/1-bed-smart-special/i3.jpg")},
            {source : require("../img/property_slider/1-bed-smart-special/i4.jpg")},
            {source : require("../img/property_slider/1-bed-smart-special/i5.jpg")},
        ],
        "1-bed-smart-special_block_2" : [
            {source : require("../img/property_slider/1-bed-smart-special/block_2/i1.jpg")},
        ],
        "1-bed-smart_block_1" : [
            {source : require("../img/property_slider/1-bed-smart/block_1/i1.jpg")},
        ],
        "1-bed-smart_block_2" : [
            {source : require("../img/property_slider/1-bed-smart/block_2/i1.jpg")},
        ],
        "1-bed-smart_block_3" : [
            {source : require("../img/property_slider/1-bed-smart/block_3/i1.jpg")},
        ],
        "1-bed-smart_block_5" : [
            {source : require("../img/property_slider/1-bed-smart/block_5/i1.jpg")},
        ],
        "1-bed-smart_block_6" : [
            {source : require("../img/property_slider/1-bed-smart/block_6/i1.jpg")},
        ],
        "2-bed-apt" : [
            {source : require("../img/property_slider/2-bed-apt/i1.jpg")},
            {source : require("../img/property_slider/2-bed-apt/i2.jpg")},
            {source : require("../img/property_slider/2-bed-apt/i3.jpg")},
            {source : require("../img/property_slider/2-bed-apt/i4.jpg")},
            {source : require("../img/property_slider/2-bed-apt/i5.jpg")},
            {source : require("../img/property_slider/2-bed-apt/i6.jpg")},
        ],
        "2-bed-apt_block_2" : [
            {source : require("../img/property_slider/2-bed-apt/block_2/i1.jpg")},
        ],
        "2-bed-apt_block_3" : [
            {source : require("../img/property_slider/2-bed-apt/block_3/i1.jpg")},
            {source : require("../img/property_slider/2-bed-apt/block_3/i2.jpg")},
        ],
        "2-bed-apt_block_4" : [
            {source : require("../img/property_slider/2-bed-apt/block_4/i1.jpg")},
            {source : require("../img/property_slider/2-bed-apt/block_4/i2.jpg")},
        ],
        "2-bed-apt_block_6" : [
            {source : require("../img/property_slider/2-bed-apt/block_6/i1.jpg")},
        ],
        "2-bed-apt_block_7" : [
            {source : require("../img/property_slider/2-bed-apt/block_7/i1.jpg")},
            {source : require("../img/property_slider/2-bed-apt/block_7/i2.jpg")},
        ],
        "2-bed-apt_block_10" : [
            {source : require("../img/property_slider/2-bed-apt/block_10/i1.jpg")},
        ],
        "2-bed-apt_block_11" : [
            {source : require("../img/property_slider/2-bed-apt/block_11/i1.jpg")},
            {source : require("../img/property_slider/2-bed-apt/block_11/i2.jpg")},
        ],
        "2-bed-ph" : [
            {source : require("../img/property_slider/2-bed-ph/i1.jpg")},
            {source : require("../img/property_slider/2-bed-ph/i2.jpg")},
            {source : require("../img/property_slider/2-bed-ph/i3.jpg")},
            {source : require("../img/property_slider/2-bed-ph/i4.jpg")},
            {source : require("../img/property_slider/2-bed-ph/i5.jpg")},
            {source : require("../img/property_slider/2-bed-ph/i6.jpg")},
            {source : require("../img/property_slider/2-bed-ph/i7.jpg")},
        ],
        "2-bed-ph_block_2" : [
            {source : require("../img/property_slider/2-bed-ph/block_2/i1.jpg")},
        ],
        "2-bed-ph_block_4" : [
            {source : require("../img/property_slider/2-bed-ph/block_4/i1.jpg")},
        ],
        "2-bed-ph_block_6" : [
            {source : require("../img/property_slider/2-bed-ph/block_6/i1.jpg")},
        ],
        "2-bed-ph_block_10" : [
            {source : require("../img/property_slider/2-bed-ph/block_10/i1.jpg")},
        ],
        "2-bed-smart" : [
            {source : require("../img/property_slider/2-bed-smart/i1.jpg")},
            {source : require("../img/property_slider/2-bed-smart/i2.jpg")},
            {source : require("../img/property_slider/2-bed-smart/i3.jpg")},
            {source : require("../img/property_slider/2-bed-smart/i4.jpg")},
            {source : require("../img/property_slider/2-bed-smart/i5.jpg")},
            {source : require("../img/property_slider/2-bed-smart/i6.jpg")},
        ],
        "2-bed-smart_block_3" : [
            {source : require("../img/property_slider/2-bed-smart/block_3/i1.jpg")},
        ],
        "2-bed-smart_block_7_flat_0" : [
            {source : require("../img/property_slider/2-bed-smart/block_7/flat_0/i1.jpg")},
        ],
        "2-bed-smart_block_7_flat_1" : [
            {source : require("../img/property_slider/2-bed-smart/block_7/flat_1/i1.jpg")},
            {source : require("../img/property_slider/2-bed-smart/block_7/flat_1/i2.jpg")},
        ],
        "3-bed-apt" : [
            {source : require("../img/property_slider/3-bed-apt/i1.jpg")},
            {source : require("../img/property_slider/3-bed-apt/i2.jpg")},
            {source : require("../img/property_slider/3-bed-apt/i3.jpg")},
            {source : require("../img/property_slider/3-bed-apt/i4.jpg")},
            {source : require("../img/property_slider/3-bed-apt/i5.jpg")},
            {source : require("../img/property_slider/3-bed-apt/i6.jpg")},
            {source : require("../img/property_slider/3-bed-apt/i7.jpg")},
            {source : require("../img/property_slider/3-bed-apt/i8.jpg")},
        ],
        "3-bed-apt_block_3" : [
            {source : require("../img/property_slider/3-bed-apt/block_3/i1.jpg")},

        ],
        "3-bed-apt_block_4" : [
            {source : require("../img/property_slider/3-bed-apt/block_4/i1.jpg")},
        ],
        "3-bed-apt_block_5" : [
            {source : require("../img/property_slider/3-bed-apt/block_5/i1.jpg")},
        ],
        "3-bed-apt_block_7" : [
            {source : require("../img/property_slider/3-bed-apt/block_7/i1.jpg")},
        ],
        "3-bed-apt_block_8" : [
            {source : require("../img/property_slider/3-bed-apt/block_8/i1.jpg")},
        ],
        "3-bed-apt_block_11" : [
            {source : require("../img/property_slider/3-bed-apt/block_11/i1.jpg")},
        ],
        "3-bed-apt_block_12" : [
            {source : require("../img/property_slider/3-bed-apt/block_12/i1.jpg")},
        ],
        "3-bed-apt_block_14" : [
            {source : require("../img/property_slider/3-bed-apt/block_14/i1.jpg")},
        ],
        "3-bed-apt_block_15" : [
            {source : require("../img/property_slider/3-bed-apt/block_15/i1.jpg")},
        ],
        "3-bed-apt_block_18" : [
            {source : require("../img/property_slider/3-bed-apt/block_18/i1.jpg")},
        ],
        "3-bed-apt_block_20" : [
            {source : require("../img/property_slider/3-bed-apt/block_20/i1.jpg")},
        ],
        "3-bed-ph" : [
            {source : require("../img/property_slider/3-bed-ph/i1.jpg")},
            {source : require("../img/property_slider/3-bed-ph/i2.jpg")},
            {source : require("../img/property_slider/3-bed-ph/i3.jpg")},
            {source : require("../img/property_slider/3-bed-ph/i4.jpg")},
            {source : require("../img/property_slider/3-bed-ph/i5.jpg")},
            {source : require("../img/property_slider/3-bed-ph/i6.jpg")},
            {source : require("../img/property_slider/3-bed-ph/i7.jpg")},
            {source : require("../img/property_slider/3-bed-ph/i8.jpg")},
            {source : require("../img/property_slider/3-bed-ph/i9.jpg")},
            {source : require("../img/property_slider/3-bed-ph/i10.jpg")},
        ],
        "3-bed-ph_block_1" : [
            {source : require("../img/property_slider/3-bed-ph/block_1/i1.jpg")},
        ],
        "3-bed-ph_block_3" : [
            {source : require("../img/property_slider/3-bed-ph/block_3/i1.jpg")},
        ],
        "3-bed-ph_block_5" : [
            {source : require("../img/property_slider/3-bed-ph/block_5/i1.jpg")},
        ],
        "3-bed-ph_block_7" : [
            {source : require("../img/property_slider/3-bed-ph/block_7/i1.jpg")},
        ],
        "3-bed-ph_block_11" : [
            {source : require("../img/property_slider/3-bed-ph/block_11/i1.jpg")},
        ],
        "3-bed-ph_block_15" : [
            {source : require("../img/property_slider/3-bed-ph/block_15/i1.jpg")},
        ],
        "3-bed-ph_block_19" : [
            {source : require("../img/property_slider/3-bed-ph/block_19/i1.jpg")},
        ],
        "3-bed-pool" : [
            {source : require("../img/property_slider/3-bed-pool/i1.jpg")},
            {source : require("../img/property_slider/3-bed-pool/i2.jpg")},
            {source : require("../img/property_slider/3-bed-pool/i3.jpg")},
            {source : require("../img/property_slider/3-bed-pool/i4.jpg")},
            {source : require("../img/property_slider/3-bed-pool/i5.jpg")},
            {source : require("../img/property_slider/3-bed-pool/i6.jpg")},
            {source : require("../img/property_slider/3-bed-pool/i7.jpg")},
            {source : require("../img/property_slider/3-bed-pool/i8.jpg")},
        ],
        "3-bed-pool_block_1" : [
            {source : require("../img/property_slider/3-bed-pool/block_1/i1.jpg")},
            {source : require("../img/property_slider/3-bed-pool/block_1/i2.jpg")},
        ],
        "3-bed-pool_block_2" : [
            {source : require("../img/property_slider/3-bed-pool/block_2/i1.jpg")},
            {source : require("../img/property_slider/3-bed-pool/block_2/i2.jpg")},
        ],
        "3-bed-pool_block_5" : [
            {source : require("../img/property_slider/3-bed-pool/block_5/i1.jpg")},
            {source : require("../img/property_slider/3-bed-pool/block_5/i2.jpg")},
        ],
        "3-bed-pool_block_6" : [
            {source : require("../img/property_slider/3-bed-pool/block_6/i1.jpg")},
            {source : require("../img/property_slider/3-bed-pool/block_6/i2.jpg")},
        ],
        "3-bed-pool_block_18" : [
            {source : require("../img/property_slider/3-bed-pool/block_18/i1.jpg")},
            {source : require("../img/property_slider/3-bed-pool/block_18/i2.jpg")},
        ],
        "3-bed-pool_block_19" : [
            {source : require("../img/property_slider/3-bed-pool/block_19/i1.jpg")},
            {source : require("../img/property_slider/3-bed-pool/block_19/i2.jpg")},
        ],
        "studio" : [
            {source : require("../img/property_slider/studio/i1.jpg")},
            {source : require("../img/property_slider/studio/i2.jpg")},
            {source : require("../img/property_slider/studio/i3.jpg")},
            {source : require("../img/property_slider/studio/i4.jpg")},
            {source : require("../img/property_slider/studio/i5.jpg")},
        ],
        "studio_block_1" : [
            {source : require("../img/property_slider/studio/block_1/i1.jpg")},
        ],
        "studio_block_4" : [
            {source : require("../img/property_slider/studio/block_4/i1.jpg")},
        ],
        "studio_block_5" : [
            {source : require("../img/property_slider/studio/block_5/i1.jpg")},
        ],
        "studio_block_6" : [
            {source : require("../img/property_slider/studio/block_6/i1.jpg")},
        ],
        "studio_block_8" : [
            {source : require("../img/property_slider/studio/block_8/i1.jpg")},
        ],
        "studio_block_18" : [
            {source : require("../img/property_slider/studio/block_18/i1.jpg")},
        ],

    },
    thumb : {
        "1-bed-apt" : [
            {source : require("../img/property_thumbs/1-bed-apt/i1.jpg")},
        ],
        "1-bed-smart" : [
            {source : require("../img/property_thumbs/1-bed-smart/i1.jpg")},
        ],
        "2-bed-apt" : [
            {source : require("../img/property_thumbs/2-bed-apt/i1.jpg")},
        ],
        "2-bed-smart" : [
            {source : require("../img/property_thumbs/2-bed-smart/i1.jpg")},
        ],
        "2-bed-ph" : [
            {source : require("../img/property_thumbs/2-bed-ph/i1.jpg")},
        ],
        "3-bed-apt" : [
            {source : require("../img/property_thumbs/3-bed-apt/i1.jpg")},
        ],
        "3-bed-pool" : [
            {source : require("../img/property_thumbs/3-bed-pool/i1.jpg")},
        ],
        "3-bed-ph" : [
            {source : require("../img/property_thumbs/3-bed-ph/i1.jpg")},
        ],
        "studio" : [
            {source : require("../img/property_thumbs/studio/i1.jpg")},
        ],

    },
    vr360 : {
        "1-bed-smart" : "https://my.matterport.com/show/?m=7NHUL62Vnde",
        "1-bed-apt" : "https://my.matterport.com/show/?m=oooEwcwR3Pt",
        "2-bed-apt" : "https://my.matterport.com/show/?m=HyQ5YtLLKeJ",
        "2-bed-ph" : "https://my.matterport.com/show/?m=eVjo2DQDJ9i",
        "2-bed-smart" : "https://my.matterport.com/show/?m=RVxBS8AyTWU",
        "3-bed-ph" : "https://my.matterport.com/show/?m=gJoQo9psPa3",
        "3-bed-pool" : "https://my.matterport.com/show/?m=y7JGWT9Hpec",
        "3-bed-apt" : "https://my.matterport.com/show/?m=3yNxxhNjmwt",
        "studio" : "https://my.matterport.com/show/?m=xV2Jm9UKydF",
    }
}