export const buildingConst = {
    "Block_1": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1230,724.2 1230,657.7 455.8,657.7 455.8,786.7 1253.9,786.7 1253.9,724.2"},
            {status : 1, level: 1,points:"1230,514.1 1130.2,514.1 455.8,514.1 455.8,657.7 1230,657.7"},
            {status : 1, level: 2,points:"1130.2,375.7 494.4,375.7 494.4,507.4 455.8,507.4 455.8,514.1 1130.2,514.1"},
            {status : 1, level: 3,points:"1130.2,347.8 1098.3,347.8 1099.6,232.1 495.7,232.1 494.4,375.7 1130.2,375.7"},

        ]
    },
    "Block_2": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"147.2,677.5 147.2,787.3 1767.4,787.3 1767.4,675.5"},
            {status : 1, level: 1,points:"1767.4,558 147.2,558 147.2,677.5 1767.4,675.5"},
            {status : 1, level: 2,points:"1767.4,540.5 1746.1,540.5 1746.1,502.1 1698.2,502.1 1698.2,440.4 193.8,440.4 193.8,502.1 \n" +
                    "\t168.5,502.1 168.5,540.5 147.2,540.5 147.2,558 1767.4,558"},
            {status : 1, level: 3,points:"1698.2,301.8 193.8,300.8 193.8,440.4 1698.2,440.4"},
        ]
    },
    "Block_3": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"728.5,694.9 570.8,694.9 570.2,796.1 729.8,782.8 1142.2,789.4 1288.5,796.1 1288.5,694.9 \n" +
                    "\t1135.5,694.9"},
            {status : 1, level: 1,points:"1140.9,592.5 725.8,592.5 571.5,578 570.8,694.9 728.5,694.9 1135.5,694.9 1288.5,694.9 1288.5,580.9"},
            {status : 1, level: 2,points:"1143.5,484.8 724.5,484.8 556.9,464.8 556.9,563.3 571.5,563.3 571.5,578 725.8,592.5 1140.9,592.5 \n" +
                    "\t1288.5,580.9 1288.5,568.6 1300.5,568.6 1300.5,530 1317.8,530 1317.8,457.3"},
            {status : 1, level: 3,points:"1143.5,354.9 723.2,354.9 531.6,308.1 531.6,438.2 556.9,439.6 556.9,464.8 724.5,484.8 1143.5,484.8 \n" +
                    "\t1317.8,457.3 1317.8,314.6"},

        ]
    },
    "Block_4": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"496.6,719.7 494.1,855.2 1513.7,855.2 1513.7,725.1"},
            {status : 1, level: 1,points:"1513.7,567.9 1501.4,567.3 500,554.3 496.6,719.7 1513.7,725.1"},
            {status : 1, level: 2,points:"1493.7,566.9 1493.3,430 519.4,409.2 517,551 500.1,551 500,554.3 1501.4,567.3"},
            {status : 1, level: 3,points:"1492.7,263.6 522.4,230.7 519.4,409.2 1493.3,430"},
        ]
    },
    "Block_5": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 3, level: 0,points:"1230,724.2 1230,657.7 455.8,657.7 455.8,786.7 1253.9,786.7 1253.9,724.2"},
            {status : 1, level: 1,points:"1230,514.1 1130.2,514.1 455.8,514.1 455.8,657.7 1230,657.7"},
            {status : 1, level: 2,points:"1130.2,375.7 494.4,375.7 494.4,507.4 455.8,507.4 455.8,514.1 1130.2,514.1"},
            {status : 1, level: 3,points:"1130.2,347.8 1098.3,347.8 1098.3,262.7 494.4,262.7 494.4,375.7 1130.2,375.7"},
            {status : 1, level: 4,points:"1098.3,173.5 511.7,173.5 511.7,234.7 494.4,234.7 494.4,262.7 1098.3,262.7"},

        ]
    },
    "Block_6": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 3, level: 0,points:"147.2,676.5 147.2,787.3 1767.4,787.3 1767.4,675.5"},
            {status : 1, level: 1,points:"1767.4,558 147.2,558 147.2,676.5 1767.4,675.5"},
            {status : 1, level: 2,points:"1767.4,540.5 1746.1,540.5 1746.1,502.1 1698.2,502.1 1698.2,440.4 193.8,440.4 193.8,502.1 \n" +
                    "\t168.5,502.1 168.5,540.5 147.2,540.5 147.2,558 1767.4,558"},


            {status : 1, level: 3,points:"1698.2,335.8 193.8,334.8 193.8,440.4 1698.2,440.4"},
            {status : 1, level: 4,points:"1698.2,242.7 193.8,242.7 193.8,334.8 1698.2,335.8"},

        ]
    },
    "Block_7": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"728.5,694.9 570.8,694.9 570.2,796.1 729.8,782.8 1142.2,789.4 1288.5,796.1 1288.5,694.9 \n" +
                    "\t1135.5,694.9"},
            {status : 1, level: 1,points:"1140.9,592.5 725.8,592.5 571.5,578 570.8,694.9 728.5,694.9 1135.5,694.9 1288.5,694.9 1288.5,580.9"},
            {status : 1, level: 2,points:"1143.5,484.8 724.5,484.8 556.9,464.8 556.9,563.3 571.5,563.3 571.5,578 725.8,592.5 1140.9,592.5 \n" +
                    "\t1288.5,580.9 1288.5,568.6 1300.5,568.6 1300.5,530 1317.8,530 1317.8,457.3"},
            {status : 1, level: 3,points:"1143.5,383.7 723.2,383.7 531.6,340.9 531.6,438.2 556.9,439.6 556.9,464.8 724.5,484.8 1143.5,484.8 \n" +
                    "\t1317.8,457.3 1317.8,346.7"},
            {status : 1, level: 4,points:"1143.5,383.7 1317.8,346.7 1317.8,248 1136.9,285.3 705.9,285.3 531.6,233.4 531.6,340.9 723.2,383.7"},


        ]
    },
    "Block_8": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"496.6,719.7 494.1,855.2 1513.7,855.2 1513.7,725.1"},
            {status : 1, level: 1,points:"1513.7,567.9 1501.4,567.3 500,554.3 496.6,719.7 1513.7,725.1"},
            {status : 1, level: 2,points:"1493.7,566.9 1493.3,430 519.4,409.2 517,551 500.1,551 500,554.3 1501.4,567.3"},
            {status : 3, level: 3,points:"1492.7,263.6 522.4,230.7 519.4,409.2 1493.3,430"},

        ]
    },
    "Block_9": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1230,724.2 1230,657.7 455.8,657.7 455.8,786.7 1253.9,786.7 1253.9,724.2"},
            {status : 1, level: 1,points:"1230,514.1 1130.2,514.1 455.8,514.1 455.8,657.7 1230,657.7"},
            {status : 1, level: 2,points:"1130.2,375.7 494.4,375.7 494.4,507.4 455.8,507.4 455.8,514.1 1130.2,514.1"},
            {status : 1, level: 3,points:"1130.2,347.8 1098.3,347.8 1099.6,232.1 495.7,232.1 494.4,375.7 1130.2,375.7"},

        ]
    },
    "Block_10": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 3, level: 0,points:"147.2,677.5 147.2,787.3 1767.4,787.3 1767.4,675.5"},
            {status : 3, level: 1,points:"1767.4,558 147.2,558 147.2,677.5 1767.4,675.5"},
            {status : 1, level: 2,points:"1767.4,540.5 1746.1,540.5 1746.1,502.1 1698.2,502.1 1698.2,440.4 193.8,440.4 193.8,502.1 \n" +
                    "\t168.5,502.1 168.5,540.5 147.2,540.5 147.2,558 1767.4,558"},
            {status : 1, level: 3,points:"1698.2,301.8 193.8,300.8 193.8,440.4 1698.2,440.4"},
        ]
    },
    "Block_11": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"728.5,694.9 570.8,694.9 570.2,796.1 729.8,782.8 1142.2,789.4 1288.5,796.1 1288.5,694.9 \n" +
                    "\t1135.5,694.9"},
            {status : 1, level: 1,points:"1140.9,592.5 725.8,592.5 571.5,578 570.8,694.9 728.5,694.9 1135.5,694.9 1288.5,694.9 1288.5,580.9"},
            {status : 1, level: 2,points:"1143.5,484.8 724.5,484.8 556.9,464.8 556.9,563.3 571.5,563.3 571.5,578 725.8,592.5 1140.9,592.5 \n" +
                    "\t1288.5,580.9 1288.5,568.6 1300.5,568.6 1300.5,530 1317.8,530 1317.8,457.3"},
            {status : 1, level: 3,points:"1143.5,354.9 723.2,354.9 531.6,308.1 531.6,438.2 556.9,439.6 556.9,464.8 724.5,484.8 1143.5,484.8 \n" +
                    "\t1317.8,457.3 1317.8,314.6"},

        ]
    },
    "Block_12": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 3, level: 0,points:"496.6,719.7 494.1,855.2 1513.7,855.2 1513.7,725.1"},
            {status : 1, level: 1,points:"1513.7,567.9 1501.4,567.3 500,554.3 496.6,719.7 1513.7,725.1"},
            {status : 3, level: 2,points:"1493.7,566.9 1493.3,430 519.4,409.2 517,551 500.1,551 500,554.3 1501.4,567.3"},
            {status : 3, level: 3,points:"1492.7,263.6 522.4,230.7 519.4,409.2 1493.3,430"},
        ]
    },
    "Block_13": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1230,724.2 1230,657.7 455.8,657.7 455.8,786.7 1253.9,786.7 1253.9,724.2"},
            {status : 1, level: 1,points:"1230,514.1 1130.2,514.1 455.8,514.1 455.8,657.7 1230,657.7"},
            {status : 1, level: 2,points:"1130.2,375.7 494.4,375.7 494.4,507.4 455.8,507.4 455.8,514.1 1130.2,514.1"},
            {status : 1, level: 3,points:"1130.2,347.8 1098.3,347.8 1099.6,232.1 495.7,232.1 494.4,375.7 1130.2,375.7"},

        ]
    },
    "Block_14": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 3, level: 0,points:"234.7,634.8 234.7,725.5 1779,725.5 1779,633.8"},
            {status : 1, level: 1,points:"1779,519 234.7,519 234.7,634.8 1779,633.8"},
            {status : 1, level: 2,points:"1779,503.1 1759.1,503.1 1759.1,396.3 261.6,396.3 261.6,501.1 234.7,501.1 234.7,519 1779,519"},
            {status : 3, level: 3,points:"1759.1,387.3 1727.2,387.3 1727.2,301.6 261.6,300.6 261.6,396.3 1759.1,396.3"},
            {status : 3, level: 4,points:"1727.2,210.8 261.6,210.8 261.6,300.6 1727.2,301.6"},

        ]
    },
    "Block_15": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 3, level: 0,points:"728.5,694.9 570.8,694.9 570.2,796.1 729.8,782.8 1142.2,789.4 1288.5,796.1 1288.5,694.9 \n" +
                    "\t1135.5,694.9"},
            {status : 1, level: 1,points:"1140.9,592.5 725.8,592.5 571.5,578 570.8,694.9 728.5,694.9 1135.5,694.9 1288.5,694.9 1288.5,580.9"},
            {status : 1, level: 2,points:"1143.5,484.8 724.5,484.8 556.9,464.8 556.9,563.3 571.5,563.3 571.5,578 725.8,592.5 1140.9,592.5 \n" +
                    "\t1288.5,580.9 1288.5,568.6 1300.5,568.6 1300.5,530 1317.8,530 1317.8,457.3"},
            {status : 1, level: 3,points:"1143.5,383.7 723.2,383.7 531.6,340.9 531.6,438.2 556.9,439.6 556.9,464.8 724.5,484.8 1143.5,484.8 \n" +
                    "\t1317.8,457.3 1317.8,346.7"},
            {status : 3, level: 4,points:"1143.5,383.7 1317.8,346.7 1317.8,248 1136.9,285.3 705.9,285.3 531.6,233.4 531.6,340.9 723.2,383.7"},
        ]
    },
    "Block_16": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"496.6,719.7 494.1,855.2 1513.7,855.2 1513.7,725.1"},
            {status : 1, level: 1,points:"1513.7,567.9 1501.4,567.3 500,554.3 496.6,719.7 1513.7,725.1"},
            {status : 1, level: 2,points:"1493.7,566.9 1493.3,430 519.4,409.2 517,551 500.1,551 500,554.3 1501.4,567.3"},
            {status : 1, level: 3,points:"1492.7,263.6 522.4,230.7 519.4,409.2 1493.3,430"},
        ]
    },
    "Block_17": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1230,724.2 1230,657.7 455.8,657.7 455.8,786.7 1253.9,786.7 1253.9,724.2"},
            {status : 1, level: 1,points:"1230,514.1 1130.2,514.1 455.8,514.1 455.8,657.7 1230,657.7"},
            {status : 1, level: 2,points:"1130.2,375.7 494.4,375.7 494.4,507.4 455.8,507.4 455.8,514.1 1130.2,514.1"},
            {status : 1, level: 3,points:"1130.2,347.8 1098.3,347.8 1099.6,232.1 495.7,232.1 494.4,375.7 1130.2,375.7"},

        ]
    },
    "Block_18": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"177.8,599.8 177.8,688.6 1611.4,688.6 1611.4,600.8"},
            {status : 1, level: 1,points:"1611.4,491.1 177.8,491.1 177.8,599.8 1611.4,600.8"},
            {status : 3, level: 2,points:"1611.4,475.1 1592.5,475.1 1592.5,380.4 198.8,379.4 198.8,478.1 177.8,478.1 177.8,491.1 1611.4,491.1"},
            {status : 1, level: 3,points:"1592.5,248.7 198.8,248.7 198.8,379.4 1592.5,380.4"},

        ]
    },
    "Block_19": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"496.6,719.7 494.1,855.2 1513.7,855.2 1513.7,725.1"},
            {status : 3, level: 1,points:"1513.7,567.9 1501.4,567.3 500,554.3 496.6,719.7 1513.7,725.1"},
            {status : 3, level: 2,points:"1493.7,566.9 1493.3,430 519.4,409.2 517,551 500.1,551 500,554.3 1501.4,567.3"},
            {status : 1, level: 3,points:"1492.7,263.6 522.4,230.7 519.4,409.2 1493.3,430"},
        ]
    },
    "Block_20": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 3, level: 0,points:"884.2,599.8 884.2,688.6 1611.4,688.6 1611.4,600.8"},
            {status : 1, level: 1,points:"1611.4,491.1 884.2,491.1 884.2,599.8 1611.4,600.8"},
            {status : 3, level: 2,points:"1611.4,475.1 1592.5,475.1 1592.5,380.4 905.1,381.4 905.1,478.1 884.2,478.1 884.2,491.1 1611.4,491.1"},
            {status : 3, level: 3,points:"1592.5,248.7 905.1,248.7 905.1,381.4 1592.5,380.4"},

        ]
    },
    "Block_21": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 3, level: 0,points:"52.8,804.5 652,823.3 701.9,804.5 1019.8,812.5 1079.7,804.5 1758.1,836.4 1758.1,1012 1462.8,1026.7 1090.3,1042.6 1031.2,1036 58.1,1022.7"},
            {status : 3, level: 1,points:"52.8,570.4 624.8,599.7 670,559.8 1019.8,593 1081,559.8 1468.4,598.3 1468.4,632.9 1622.4,644.9 1622.4,694.1 1764.7,700.8 1758.1,836.4 1079.7,804.5 1019.8,812.5 701.9,804.5 651.4,824.3 52.8,804.5"},
            {status : 3, level: 2,points:"51.3,329.3 651.4,407.9 680.6,370.9 973.3,406.8 1026.5,358.9 1398.9,414.8 1398.9,477.3 1534.6,493.3 1534.6,522.5 1764.7,550.5 1761.1,701.1 1622.4,694.1 1622.4,644.9 1468.4,632.9 1468.4,598.3 1081,559.8 1019.8,593 670,559.8 624.8,599.7 52.8,570.4"},
            {status : 3, level: 3,points:"51.3,91.5 647.4,196.6 680.6,111.5 973.3,175.3 1026.5,111.5 1400.2,196.6 1400.2,261.8 1534.6,292.4 1534.6,362.9 1765.9,398.8 1764.7,550.5 1534.6,522.5 1534.6,493.3 1398.9,477.3 1398.9,414.8 1026.5,358.9 973.3,406.8 680.6,370.9 651.4,408.2 51.3,329.3"},

        ]
    },



}