import  React, {useState, useEffect, useContext} from 'react';
import GridItem from './GridItem';
import '../css/GridView.css';
import AppDataContext from "./context/AppDataContext";
import LoadingOverlay from "./LoadingOverlay";


function GridView() {
    const {appData, setAppData} = useContext(AppDataContext);
    const [gridItems,setGridItems] = useState(null);

    useEffect(()=> {
        if(appData.loaded)
        {
            let rows = [];

            appData.filteredProperties.forEach(function(property,index){
                rows.push(<GridItem key={property.id} property={property} />);
            })
            console.log(appData.filteredProperties)
            setGridItems(rows);

        }
    },[appData.loaded,appData.filteredProperties])





  return (
      <section className={gridItems ? "primary grid-view " :"primary grid-view loading"} >
          <LoadingOverlay loaded={!!gridItems}/>
          <div className="container-fluid">
              <div className="row">
                  { gridItems }
              </div>
          </div>
      </section>
  )
  
}

export default GridView;