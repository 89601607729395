import React from 'react';
import SubMenuItem from "./SubMenuItem";
import '../css/SubMenu.css';
const SubMenu = ({submenu,onClickButtonBack}) => {

    return (
        <div className="submenu-wrap">
            <div className="inner">
                <div className="submenu">
                    {submenu.map((sub,i) => (
                        <SubMenuItem title={sub.title} link={sub.link} isLast={submenu.length === (i+1)} key={sub.id} />
                    ))}
                </div>
            </div>

            <button className="button-back" onClick={onClickButtonBack} />
        </div>
    );
};

export default SubMenu;