import React, {useContext, useEffect, useState} from 'react';
import "../css/Favorites.css";
import {apiConst} from "../constant/apiConst";
import UserContext from "./context/UserContext";
import ClientContext from "./context/ClientContext";
import FavoriteList from "./FavoriteList";
import LoadingOverlay from "./LoadingOverlay";
const Favorites = (props) => {


    return (
        <div className="fav-wrap">

            <div className="inner">
                <h2>FAVORITES</h2>
                <div className="content">
                    <FavoriteList />
                </div>

            </div>
            <button className="button-back" onClick={props.onClickButtonBack} />
        </div>
    );
};

export default Favorites;