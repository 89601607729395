import React, {useContext, useEffect, useState} from "react";
import '../css/Modal.css';
import {appUtil} from "../constant/appUtil";
import ClientContext from "./context/ClientContext";
import FavContext from "./context/FavContext";
import {Link, useHistory} from "react-router-dom";
import AlertContext from "./context/AlertContext";
import {apiConst} from "../constant/apiConst";
function SessionTimeout() {
    const startTime = 8*60;
    const history = useHistory();
    const [timer, setTimer] = useState(startTime);
    const {client,setClient} = useContext(ClientContext);
    const {favs,setFavs} = useContext(FavContext);
    const {alert,setAlert} = useContext(AlertContext);

    useEffect(() => {
        const myInterval = setInterval(() => {
            if (timer > 0) {
                setTimer(timer - 1);
            }
        }, 1000);
        const resetTimeout = () => {
            setTimer(startTime);
        };
        const events = [

            "click",
            "scroll"

        ];
        for (let i in events) {
            window.addEventListener(events[i], resetTimeout);
        }

        return () => {
            clearInterval(myInterval);
            for (let i in events) {
                window.removeEventListener(events[i], resetTimeout);
            }
        };

    });

    useEffect(()=> {
        console.log(timer);
        if(timer === 0) {
            terminateSession();
        }
        else if(timer < 5*60) {
            showModal();
        }

    },[timer])

    function showModal(){

        document.querySelector("body").classList.add("modal-open");
        document.querySelector(".session-timeout-modal").classList.add("show");
        document.querySelector(".modal-backdrop").classList.add("show");

    }

    function hideModal(){
        document.querySelector("body").classList.remove("modal-open");
        document.querySelector(".session-timeout-modal").classList.remove("show");
        document.querySelector(".modal-backdrop").classList.remove("show");

    }

    function continueSession(){
        setTimer(startTime);
        hideModal();
    }

    function terminateSession(){
        apiConst.presentationTerminate(localStorage.getItem("presentation_id"));
        setClient(null);
        setFavs([]);
        localStorage.removeItem("presentation_id");
        localStorage.removeItem("client");
        localStorage.removeItem("favs");
        history.push("/");
        hideModal();
        setAlert("Presentation is terminated");

    }

    return (
        <div className="modal fade session-timeout-modal" id="default-modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle"></h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={continueSession} >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h3>Presentation Inactivity </h3>
                        <p>Presentation will terminate in </p>
                        <h1>{appUtil.convertSecondsToMinutes(timer)}</h1>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="default-button secondary" data-dismiss="modal" onClick={terminateSession}>Terminate</button>
                        <button type="button" className="default-button" onClick={continueSession}>Continue</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SessionTimeout;