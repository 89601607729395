import React from 'react';
import {appUtil} from "../constant/appUtil";
import LazyLoad from "react-lazyload";
import {Link} from "react-router-dom";
import {propertyConst} from "../constant/propertyConst";

function GridItem({property}) {
  function getImage(){
    let thumbs = propertyConst.thumb[appUtil.convertToSlug(property.room_type.label)];

    return (thumbs[0].source);
  }
  return (
  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 item">
    <Link to={"/property-view/"+property.id}>
    <div className="inner">

      <LazyLoad><img className="img-fluid" src={getImage()} alt=""/></LazyLoad>
      
      <div className="details">
        <h3>{property.code}</h3>
        <table>
          <tbody>

            <tr>
              <td>FLOOR TYPE</td>
              <td>{property.room_type.label}</td>
            </tr>
            <tr>
              <td>INTERNAL</td>
              <td>{property.net_area} m<sup>2</sup></td>
            </tr>
            <tr>
              <td>EXTERNAL</td>
              <td>{property.out_area} m<sup>2</sup></td>
            </tr>
            <tr>
              <td>GROSS</td>
              <td>{property.gross_area} m<sup>2</sup></td>
            </tr>
            <tr>
              <td>POOL</td>
              <td>{property.pool_area} m<sup>2</sup></td>
            </tr>
            <tr>
              <td>ROOF</td>
              <td>{property.roof_area} m<sup>2</sup></td>
            </tr>
          </tbody>
        </table>
      </div>
      
    </div>
    </Link>
  </div>

  );
}

export default GridItem;