import React, {useEffect, useState} from 'react';
import {
  BrowserRouter as Router} from "react-router-dom";

import './css/App.css';

import UserContext from "./components/context/UserContext.js";
import FilterContext from "./components/context/FilterContext.js";
import AppDataContext from "./components/context/AppDataContext";
import {apiConst} from "./constant/apiConst";
import AlertContext from "./components/context/AlertContext";
import ClientContext from "./components/context/ClientContext";
import FavContext from "./components/context/FavContext";
import Routes from "./components/Routes";
import ConfirmContext from "./components/context/ConfirmContext";
import LightBoxContext from "./components/context/LightBoxContext";

export default function App() {

  const filterDefaultValues = {
    floorType: [],
    priceMin: 0,
    priceMax: 300000,
    status: false
  };

  const appDataDefaultValues = {
    isFilterModeActive: false,
    properties : null,
    filteredProperties : null,
    buildings : null,
    leadSources : null,
    leadStatuses : null,
    roomTypes :null,
    statuses : null,
    loaded : false,
  };


  const [filter, setFilter] = useState(filterDefaultValues);
  const filterValue = { filter, setFilter };

  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || null);
  const userValue = { user, setUser };

  const [client, setClient] = useState(JSON.parse(localStorage.getItem('client')) || null);
  const clientValue = { client, setClient };

  const [favs, setFavs] = useState(JSON.parse(localStorage.getItem('favs')) || []);
  const favsValue = { favs, setFavs };

  const [appData, setAppData] = useState(appDataDefaultValues);
  const appDataValue = { appData, setAppData };

  const [alert, setAlert] = useState(null);
  const alertValue = { alert, setAlert };

  const [confirm, setConfirm] = useState({show:false,text:"",onConfirm:null,onUnconfirm:null,question:""});
  const confirmValue = { confirm, setConfirm };

  const [lightBox, setLightBox] = useState(false);
  const lightBoxValue = { lightBox, setLightBox };

  const [is360Loaded,setIs360Loaded] = useState(false);


  useEffect(() => {
    Promise.all([
      apiConst.getProperties(),
      apiConst.getBuildings(),
      apiConst.getLeadSources(),
      apiConst.getLeadStatuses(),
      apiConst.getRoomTypes(),
      apiConst.getStatuses(),
    ]).then(([properties, buildings, leadSources,leadStatuses,roomTypes,statuses]) => {

      let newAppData = {...appData};
      newAppData.properties = newAppData.filteredProperties = properties;
      newAppData.buildings = buildings;
      newAppData.leadSources = leadSources;
      newAppData.leadStatuses = leadStatuses;
      newAppData.roomTypes = roomTypes;
      newAppData.statuses = statuses;
      newAppData.loaded = true
      setAppData(newAppData);
      //console.log(newAppData)

    }).catch((err) => {
      console.log(err);
    });
  },[])

  window.addEventListener('load360',function(){
    setTimeout(function(){
      setIs360Loaded(true);
    },500)
  })



  return (
    <div>
      <UserContext.Provider value={userValue}>
        <ClientContext.Provider value={clientValue}>
          <FilterContext.Provider value={filterValue}>
            <AppDataContext.Provider value={appDataValue}>
              <AlertContext.Provider value={alertValue}>
                <ConfirmContext.Provider value={confirmValue}>
                  <LightBoxContext.Provider value={lightBoxValue}>
                <FavContext.Provider value={favsValue}>
                  <Router basename={process.env.PUBLIC_URL}>
                    <Routes is360Loaded={is360Loaded} alert={alert} />
                  </Router>
              </FavContext.Provider>
                  </LightBoxContext.Provider>
              </ConfirmContext.Provider>
              </AlertContext.Provider>
            </AppDataContext.Provider>
          </FilterContext.Provider>
        </ClientContext.Provider>
      </UserContext.Provider>
    </div>
    
  );
}