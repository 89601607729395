import React, {useCallback, useEffect, useState} from 'react';
import "../css/BuildingLabel.css";
const BuildingLabel = ({propId,title,total,isVilla}) => {

    useEffect(() => {
        let el = document.getElementById("labels__item-"+propId);
        el.classList.add("animated");
        setTimeout(()=> {
            el.classList.remove("animated");
        },300)


    },[total])

    let className = "";
    if(isVilla)
        className = "labels__item villa";
    else
        className = "labels__item"

    if(!total)
        className += " labels__item--hide";
    else
        className += " labels__item--show";
    return (

        <div className={className} id={"labels__item-" + propId}>
            <div className="inner">
                <div className="labels__title">{title}</div>
                <div className="labels__value" >{total}</div>
            </div>
        </div>

    );
};

export default BuildingLabel;