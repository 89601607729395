import React from 'react';
import {ReactComponent as MarkerIcon} from '../img/map-marker.svg';
import {ReactComponent as MarkerPointIcon} from '../img/map-marker-point.svg';
const MapMarker = ({lat,lng,small,title,onMarkerClick}) => {
    return (
        <div onClick={() => onMarkerClick(lat,lng)}>
            { small ? <div className="map-marker-point"><span>{title}</span><MarkerPointIcon lat={lat} lng={lng}/></div> : <MarkerIcon className="map-marker" lat={lat} lng={lng}/> }
        </div>
    );
};

export default MapMarker;