import React, {useEffect, useState} from 'react';
import AwesomeSlider from "react-awesome-slider";
import 'react-awesome-slider/dist/styles.css';
import "../css/Slider.css"
const Slider = ({gallery}) => {

    const [activeSlide,setActiveSlide] = useState(1)
    const [caption,setCaption] = useState(gallery[0].caption)
    const [dark,setDark] = useState(false)
    const totalSlide = gallery.length;




    function onSliderChange(e)
    {
        setActiveSlide(e.currentIndex);
        setCaption(gallery[e.currentIndex].caption);
        setDark(gallery[e.currentIndex].darktext);
    }

    return (
        <div className="slider" onContextMenu={(e)=> e.preventDefault()}>
            <AwesomeSlider className="aws-btn" bullets={false} fillParent={true} infinite={true}
                            onTransitionEnd={onSliderChange}
                           media={gallery}
            />
            <p className={dark ? "caption dark" : "caption"}>{caption}</p>
            <p className="active-slide">{activeSlide+1}/{totalSlide}</p>
        </div>
    );
};

export default Slider;