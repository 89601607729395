import MapMarker from "../components/MapMarker";
import GoogleMap from "google-map-react";
import React from "react";

export const appConst = {
    menu: {
        gallery : [
            {id:1, title:'General Site', link: '/gallery/general'},
            {id:2, title:'Facilities', link: '/gallery/facilities'},
            {id:3, title:'Beach', link: '/gallery/beach'},
            {id:4, title:'Restaurant / Sunset Bar', link: '/gallery/restaurant'},
            {id:5, title:'Interior', link: '/gallery/interior'},
            {id:6, title:'Video', link: '/gallery/video'},

        ],
        location : [
            {id:1, title:'Ercan Airport', link: '/location/1', lat:35.15893495001439,  lng:33.50352066272562,},
            {id:2, title:'Larnaca Airport', link: '/location/2', lat:34.87237387022499,  lng:33.62049915876995,},
            {id:3,title:'Kyrenia/Girne',link:"/location/3", lat:35.33227084849823,  lng:33.31997141761194,},
            {id:4,title:'Famagusta',link:"/location/4", lat:35.12047178354011,  lng:33.93826072092238},
            {id:6,title:'Lefkosa',link:"/location/6", lat:35.19830428703298,  lng:33.35482973085504},
            {id:7,title:'Famagusta State Hospital',link:"/location/7", lat:35.15591597938096,  lng:33.90404739107839,},
            {id:8,title:'Bafra Taxi',link:"/location/8", lat:35.36946572885801,  lng:34.074277998424115},
            {id:9,title:'Kaya Artemis Casino',link:"/location/9", lat:35.36753661576767,  lng:34.07522927409142,},
            {id:10,title:'Limak Casino',link:"/location/10", lat:35.3584572372768,  lng: 34.07096443025278 },
            {id:11,title:'Concorde Casino',link:"/location/11", lat:35.35518035605968,     lng:34.070403266582005},
            {id:12,title:'Noah\'s Ark Hotel',link:"/location/12", lat:35.37959272775783,  lng:34.08901099842422},

        ]

    },
    gallery : {

        general : [
            {caption:'', source: require("../img/gallery/general/i1.jpg")},
            {caption:'', source: require("../img/gallery/general/i2.jpg")},
            {caption:'', source: require("../img/gallery/general/i3.jpg")},
            {caption:'', source: require("../img/gallery/general/i4.jpg")},
            {caption:'', source: require("../img/gallery/general/i5.jpg")},
            {caption:'', source: require("../img/gallery/general/i6.jpg")},
            {caption:'', source: require("../img/gallery/general/i7.jpg")},
            {caption:'', source: require("../img/gallery/general/i8.jpg")},
            {caption:'', source: require("../img/gallery/general/i9.jpg")},
            {caption:'', source: require("../img/gallery/general/i10.jpg")},
            {caption:'', source: require("../img/gallery/general/i11.jpg")},
            {caption:'', source: require("../img/gallery/general/i12.jpg")},
            {caption:'', source: require("../img/gallery/general/i13.jpg")},
            {caption:'', source: require("../img/gallery/general/i14.jpg")},
            {caption:'', source: require("../img/gallery/general/i15.jpg")},
            {caption:'', source: require("../img/gallery/general/i16.jpg")},
            {caption:'', source: require("../img/gallery/general/i17.jpg")},
            {caption:'', source: require("../img/gallery/general/i18.jpg")},
            {caption:'', source: require("../img/gallery/general/i19.jpg")},
        ],
        facilities : [
            {id:1,darktext:false,caption:'Sunset Bar', source: require("../img/gallery/facilities/i1.jpg"), thumb: require("../img/gallery/facilities/thumb/i1.jpg")},
            {id:2,darktext:false,caption:'Yoga Platform', source: require("../img/gallery/facilities/i2.jpg"), thumb: require("../img/gallery/facilities/thumb/i2.jpg")},
            {id:3,darktext:false,caption:'Bedouin Seating Area', source: require("../img/gallery/facilities/i3.jpg"), thumb: require("../img/gallery/facilities/thumb/i3.jpg")},
            {id:4,darktext:false,caption:'Amphitheatre', source: require("../img/gallery/facilities/i4.jpg"), thumb: require("../img/gallery/facilities/thumb/i4.jpg")},
            {id:5,darktext:false,caption:'Petanque', source: require("../img/gallery/facilities/i5.jpg"), thumb: require("../img/gallery/facilities/thumb/i5.jpg")},
            {id:6,darktext:false,caption:'Beach Cabanas', source: require("../img/gallery/facilities/i6.jpg"), thumb: require("../img/gallery/facilities/thumb/i6.jpg")},
            {id:7,darktext:false,caption:'Watersports & Bike Hire Hut', source: require("../img/gallery/facilities/i7.jpg"), thumb: require("../img/gallery/facilities/thumb/i7.jpg")},
            {id:8,darktext:false,caption:'Restaurant', source: require("../img/gallery/facilities/i8.jpg"), thumb: require("../img/gallery/facilities/thumb/i8.jpg")},
            {id:9,darktext:false,caption:'Cross Fit & Kids Climbing Wall', source: require("../img/gallery/facilities/i9.jpg"), thumb: require("../img/gallery/facilities/thumb/i9.jpg")},
            {id:10,darktext:false,caption:'Gym', vr:"https://my.matterport.com/show/?m=UdN1rDLsfMP", source: require("../img/gallery/facilities/i10.jpg"), thumb: require("../img/gallery/facilities/thumb/i10.jpg")},
            {id:11,darktext:false,caption:'Indoor Pool',vr:"https://my.matterport.com/show/?m=UdN1rDLsfMP", source: require("../img/gallery/facilities/i11.jpg"), thumb: require("../img/gallery/facilities/thumb/i11.jpg")},
            {id:12,darktext:false,caption:'Spa',vr:"https://my.matterport.com/show/?m=UdN1rDLsfMP", source: require("../img/gallery/facilities/i12.jpg"), thumb: require("../img/gallery/facilities/thumb/i12.jpg")},
            {id:22,darktext:false,caption:'Hamam',vr:"https://my.matterport.com/show/?m=UdN1rDLsfMP", source: require("../img/gallery/facilities/i13.jpg"), thumb: require("../img/gallery/facilities/thumb/i13.jpg")},
            {id:13,darktext:false,caption:'Kids Club', source: require("../img/gallery/facilities/i14.jpg"), thumb: require("../img/gallery/facilities/thumb/i14.jpg")},
            {id:14,darktext:false,caption:'Football Pitch', source: require("../img/gallery/facilities/i15.jpg"), thumb: require("../img/gallery/facilities/thumb/i15.jpg")},
            {id:15,darktext:false,caption:'Tennis & Basketball Court', source: require("../img/gallery/facilities/i16.jpg"), thumb: require("../img/gallery/facilities/thumb/i16.jpg")},
            {id:16,darktext:false,caption:'Waterslide Park', source: require("../img/gallery/facilities/i17.jpg"), thumb: require("../img/gallery/facilities/thumb/i17.jpg")},
            {id:17,darktext:false,caption:'Mini Golf', source: require("../img/gallery/facilities/i18.jpg"), thumb: require("../img/gallery/facilities/thumb/i18.jpg")},
            {id:18,darktext:false,caption:'Kids Playground', source: require("../img/gallery/facilities/i19.jpg"), thumb: require("../img/gallery/facilities/thumb/i19.jpg")},
            {id:19,darktext:false,caption:'Outdoor Freeform Pool', source: require("../img/gallery/facilities/i20.jpg"), thumb: require("../img/gallery/facilities/thumb/i20.jpg")},
            {id:20,darktext:false,caption:'Pool Bar & Towel Hut & BBQ Area', source: require("../img/gallery/facilities/i21.jpg"), thumb: require("../img/gallery/facilities/thumb/i21.jpg")},
            {id:21,darktext:false,caption:'Organic Garden', source: require("../img/gallery/facilities/i22.jpg"), thumb: require("../img/gallery/facilities/thumb/i22.jpg")},

        ],
        beach : [
            {caption:'', source: require("../img/gallery/beach/i1.jpg")},
            {caption:'', source: require("../img/gallery/beach/i2.jpg")},
            {caption:'', source: require("../img/gallery/beach/i3.jpg")},
            {caption:'', source: require("../img/gallery/beach/i4.jpg")},
            {caption:'', source: require("../img/gallery/beach/i5.jpg")},
            {caption:'', source: require("../img/gallery/beach/i6.jpg")},
            {caption:'', source: require("../img/gallery/beach/i7.jpg")},
            {caption:'', source: require("../img/gallery/beach/i8.jpg")},
            {caption:'', source: require("../img/gallery/beach/i9.jpg")},
            {caption:'', source: require("../img/gallery/beach/i10.jpg")},
            {caption:'', source: require("../img/gallery/beach/i11.jpg")},
            {caption:'', source: require("../img/gallery/beach/i12.jpg")},
            {caption:'', source: require("../img/gallery/beach/i13.jpg")},
            {caption:'', source: require("../img/gallery/beach/i14.jpg")},
            {caption:'', source: require("../img/gallery/beach/i15.jpg")},
            {caption:'', source: require("../img/gallery/beach/i16.jpg")},
        ],
        restaurant : [
            {caption:'', source: require("../img/gallery/restaurant/i1.jpg")},
            {caption:'', source: require("../img/gallery/restaurant/i2.jpg")},
            {caption:'', source: require("../img/gallery/restaurant/i3.jpg")},
            {caption:'', source: require("../img/gallery/restaurant/i4.jpg")},
            {caption:'', source: require("../img/gallery/restaurant/i5.jpg")},
            {caption:'', source: require("../img/gallery/restaurant/i6.jpg")},
            {caption:'', source: require("../img/gallery/restaurant/i7.jpg")},
            {caption:'', source: require("../img/gallery/restaurant/i8.jpg")},
            {caption:'', source: require("../img/gallery/restaurant/i9.jpg")},
            {caption:'', source: require("../img/gallery/restaurant/i10.jpg")},
            {caption:'', source: require("../img/gallery/restaurant/i11.jpg")},
            {caption:'', source: require("../img/gallery/restaurant/i12.jpg")},
            {caption:'', source: require("../img/gallery/restaurant/i13.jpg")},
        ],

        interior : [
            {caption:'', source: require("../img/gallery/interior/i1.jpg")},
            {caption:'', source: require("../img/gallery/interior/i2.jpg")},
            {caption:'', source: require("../img/gallery/interior/i3.jpg")},
            {caption:'', source: require("../img/gallery/interior/i4.jpg")},
            {caption:'', source: require("../img/gallery/interior/i5.jpg")},
            {caption:'', source: require("../img/gallery/interior/i6.jpg")},
            {caption:'', source: require("../img/gallery/interior/i7.jpg")},
            {caption:'', source: require("../img/gallery/interior/i8.jpg")},
            {caption:'', source: require("../img/gallery/interior/i9.jpg")},
            {caption:'', source: require("../img/gallery/interior/i10.jpg")},
            {caption:'', source: require("../img/gallery/interior/i11.jpg")},
            {caption:'', source: require("../img/gallery/interior/i12.jpg")},
            {caption:'', source: require("../img/gallery/interior/i13.jpg")},
            {caption:'', source: require("../img/gallery/interior/i14.jpg")},
            {caption:'', source: require("../img/gallery/interior/i15.jpg")},
            {caption:'', source: require("../img/gallery/interior/i16.jpg")},
            {caption:'', source: require("../img/gallery/interior/i17.jpg")},
            {caption:'', source: require("../img/gallery/interior/i18.jpg")},
            {caption:'', source: require("../img/gallery/interior/i19.jpg")},
        ],
    },
    brochure : [
        {image: require('../img/brochure/b0.jpg')},
        {image: require('../img/brochure/b1.jpg')},
        {image: require('../img/brochure/b2.jpg')},
        {image: require('../img/brochure/b3.jpg')},
        {image: require('../img/brochure/b4.jpg')},
        {image: require('../img/brochure/b5.jpg')},
        {image: require('../img/brochure/b6.jpg')},
        {image: require('../img/brochure/b7.jpg')},
        {image: require('../img/brochure/b8.jpg')},
        {image: require('../img/brochure/b9.jpg')},
        {image: require('../img/brochure/b10.jpg')},
        {image: require('../img/brochure/b11.jpg')},
        {image: require('../img/brochure/b12.jpg')},
        {image: require('../img/brochure/b13.jpg')},
        {image: require('../img/brochure/b14.jpg')},
        {image: require('../img/brochure/b15.jpg')},
        {image: require('../img/brochure/b16.jpg')},
        {image: require('../img/brochure/b17.jpg')},
        {image: require('../img/brochure/b18.jpg')},
        {image: require('../img/brochure/b19.jpg')},
        {image: require('../img/brochure/b20.jpg')},
        {image: require('../img/brochure/b21.jpg')},
        {image: require('../img/brochure/b22.jpg')},
        {image: require('../img/brochure/b23.jpg')},
        {image: require('../img/brochure/b24.jpg')},
        {image: require('../img/brochure/b25.jpg')},
        {image: require('../img/brochure/b26.jpg')},
        {image: require('../img/brochure/b27.jpg')},
        {image: require('../img/brochure/b28.jpg')},
        {image: require('../img/brochure/b29.jpg')},
        {image: require('../img/brochure/b30.jpg')},
        {image: require('../img/brochure/b31.jpg')},
        {image: require('../img/brochure/b32.jpg')},
        {image: require('../img/brochure/b33.jpg')},
        {image: require('../img/brochure/b34.jpg')},
        {image: require('../img/brochure/b35.jpg')},
        {image: require('../img/brochure/b36.jpg')},
        {image: require('../img/brochure/b38.jpg')},
        {image: require('../img/brochure/b39.jpg')},
        {image: require('../img/brochure/b40.jpg')},
        {image: require('../img/brochure/b0.jpg')},
    ],

    status : {
        available : 1,
        reserved : 2,
        sold : 3,
        not_released : 4
    },
    getStatusTitle : function(id){
        let str = "";
        if(id === 1)  str = "Available";
        if(id === 2)  str = "Reserved";
        if(id === 3)  str = "Sold";
        if(id === 4)  str = "Not Released";

        return str;
    },
    role : {
        sales_rep : 1,
        sales_manager : 2,
        general_manager : 3,
        broker : 4
    },
    showModal : function(className){
        document.querySelector("body").classList.add("modal-open");
        document.querySelector(".modal."+className).classList.add("show");
        document.querySelector(".modal-backdrop").classList.add("show");
    },
    closeModal : function(className){
        document.querySelector("body").classList.remove("modal-open");
        document.querySelector(".modal."+className).classList.remove("show");
        document.querySelector(".modal-backdrop").classList.remove("show");
    }

};