import React, {useContext, useEffect, useState} from 'react';
import "../css/FavoriteList.css";
import {appUtil} from "../constant/appUtil";
import {apiConst} from "../constant/apiConst";
import AlertContext from "./context/AlertContext";
import LoadingOverlay from "./LoadingOverlay";
import ClientContext from "./context/ClientContext";
import FavContext from "./context/FavContext";
import {Link} from "react-router-dom";

const FavoriteList = () => {
    const {client,setClient} = useContext(ClientContext);
    const {favs,setFavs} = useContext(FavContext);
    const {alert,setAlert} = useContext(AlertContext);
    const [deleteActive,setDeleteActive] = useState(false);
    const [selectedFav,setSelectedFav] = useState([]);


    useEffect(() => {

        selectedFav.length && setDeleteActive(true);

    },[selectedFav])


    function onFavChange(e)
    {
        if(e.target.checked)
        {
            setSelectedFav([...selectedFav,parseInt(e.target.value)])
        }
        else
        {
            let updatedSelectedFav = [...selectedFav]
            updatedSelectedFav.forEach((el,i) => {
                if(el === parseInt(e.target.value))
                    updatedSelectedFav.splice(i,1);
            });
            setSelectedFav(updatedSelectedFav);
        }

    }

    function onClickDelete()
    {

        if(selectedFav.length)
        {

            document.getElementById("loading-overlay").classList.remove("hide-loading-overlay")
            let ids = selectedFav.join();
            apiConst.favMultipleDelete(ids).then(result => {
                if(result.response)
                {
                    document.getElementById("loading-overlay").classList.add("hide-loading-overlay");
                    setAlert("Selected favorites deleted successfully");
                }
                else
                {
                    setAlert("Server error when deleting. Try again later")
                }

                let newFavs = [...favs];
                if(newFavs.length)
                {
                    let selectedFavIndex = []
                    newFavs.forEach((fav,i) => {
                        selectedFav.forEach((selected,j) => {
                            if(selected === fav.id) selectedFavIndex.push(i);
                        })
                    })

                    for (let i = selectedFavIndex.length -1; i >= 0; i--)
                        newFavs.splice(selectedFavIndex[i],1);


                    setFavs(newFavs);
                    localStorage.setItem("favs",JSON.stringify(newFavs));

                }

            })

        }
    }
    return (
        <div>
            <LoadingOverlay loaded={true}/>
            { favs.length === 0 &&
            <div className="no-fav-container">
                <div className="no-fav"></div>
                <span>No Favorite</span>
            </div>
            }
            { favs.length > 0 &&
            <div>
                <div className="fav-list">

                    {favs && favs.map(fav => (
                        <table key={fav.id} className="table">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th><Link to={"/property-view/" + fav.property.id}>{fav.property.code}</Link></th>
                                <th>{fav.property.status.label}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td rowSpan="3">
                                    <label className="check-container">
                                        <input type="checkbox" value={fav.id} onChange={e => onFavChange(e)} />
                                        <span className="checkmark"/>
                                    </label>

                                </td>
                                <td>NET AREA</td>
                                <td>{fav.property.net_area} m<sup>2</sup></td>

                            </tr>
                            <tr>
                                <td>FLOOR TYPE</td>
                                <td>{fav.property.room_type.label}</td>
                            </tr>
                            <tr>
                                <td>PRICE</td>
                                <td>{appUtil.formatPrice(fav.property.price)}</td>
                            </tr>
                            </tbody>
                        </table>

                    ))}

                </div>
                <button className={deleteActive ? "default-button button-delete" : "default-button button-delete disabled"} disabled={!deleteActive} onClick={onClickDelete} >DELETE</button>
            </div>}
        </div>

    );
};

export default FavoriteList;