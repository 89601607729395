import React, {useState, useContext, useEffect} from 'react';
import {
    Link, withRouter,
} from "react-router-dom";
import '../css/Filter.css';
import FilterContext from "./context/FilterContext.js";
import AppDataContext from "./context/AppDataContext";
import {appUtil} from "../constant/appUtil";

function Filter(props) {

    const { filter, setFilter } = useContext(FilterContext);
    const { appData, setAppData } = useContext(AppDataContext);

    const priceUnit = 10000;
    const [sliderValueMin,setSliderValueMin] = useState(filter.priceMin/priceUnit);
    const [sliderValueMax,setSliderValueMax] = useState(filter.priceMax/priceUnit);


    useEffect(() => {
        let newAppData = {...appData};
        newAppData.isFilterModeActive = true;
        setAppData(newAppData);


    },[]);

    useEffect(() => {

    },[sliderValueMin,sliderValueMax])

    function ChangeStatus(status){

        let newFilter = {...filter};
        newFilter.status = status;
        setFilter(newFilter);

    }

    function ChangeFloorType(floorType){

        let newFilter = {...filter};
        const index = newFilter.floorType.indexOf(floorType);
        if (index > -1) {
            newFilter.floorType.splice(index, 1);
        }
        else {
            newFilter.floorType.push(floorType);
        }

        setFilter(newFilter);

    }

    function ChangePrice(type,price){

        let newFilter = {...filter};
        if(type === "min") newFilter.priceMin = price;
        if(type === "max") newFilter.priceMax = price;
        setFilter(newFilter);

    }

    useEffect(() =>{
        if(appData.isFilterModeActive)
        updateFilteredProperties()
    },[filter])

    function updateFilteredProperties()
    {
        let newAppData = {...appData};
        newAppData.filteredProperties = appData.properties.filter(function(p) {
            if(filter.floorType.length)
            {
                if( (!filter.floorType.includes(p.room_type_id))) return false;
            }
            if(filter.status)
            {
                if(p.status_id !== parseInt(filter.status)) return false;
            }
            if(filter.priceMin && filter.priceMax)
            {
                if(p.price < filter.priceMin || p.price > filter.priceMax) return false;

            }
            return true;
        });

        setAppData(newAppData);
    }

    function onClickButtonClear(){

        document.getElementById("filter-floor-type").reset();
        document.getElementById("filter-status").reset();
        setSliderValueMin(0/priceUnit)
        setSliderValueMax(300000/priceUnit)
        setFilter({
            floorType: [],
            priceMin: 0,
            priceMax: 300000,
            status: false
        });

        let newAppData = {...appData};

        newAppData.filteredProperties = newAppData.properties;
        setAppData(newAppData);

    }

  return (

    <div className="filter-wrap">

        <div className="inner">

            <h2>FILTER</h2>
            
            <section>
                <h3>Floor Types</h3>
                <form className="floor-type" id="filter-floor-type">
                    <div className="row no-gutters">
                    {appData.roomTypes && appData.roomTypes.map(room => (
                        <div className="col-6" key={room.id}>
                            <div className="form-group" >
                                <label className="check-container">{room.label}
                                    <input type="checkbox" id={room.label} name="room" value={room.id} checked={filter.floorType.includes(room.id)} onChange={()=> {ChangeFloorType(room.id);}} />
                                    <span className="checkmark" />
                                </label>
                            </div>

                        </div>

                    ))}
                    </div>
                </form>

            </section>



            <section>
                <h3>Status</h3>
               
                <form className="status" id="filter-status">
                    <div className="row no-gutters">
                    {appData.statuses && appData.statuses.map(status => (
                        <div className="col-6" key={status.id}>
                        <div className="form-group" >
                            <label className="check-container">{status.label}
                                <input type="radio" id={status.label} name="status" value={status.id} checked={filter.status === status.id} onChange={()=> {ChangeStatus(status.id);}} />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        </div>
                    ))}
                    </div>
                </form>
                

            </section>

            <section>

                <h3>View</h3>

                <Link to="/" >
                    <button className={props.location.pathname === "/" ? "button-view map active" :"button-view map"  }>
                        Map
                    </button>
                </Link>

                <Link to="/grid-view" >
                    <button className={props.location.pathname === "/grid-view" ? "button-view grid active" :"button-view grid"  }>
                        Grid
                    </button>
                </Link>

                <Link to="/list-view" >
                    <button className={props.location.pathname === "/list-view" ? "button-view list active" :"button-view list"  }>
                        List
                    </button>
                </Link>

                

                

            </section>            
            
        </div>

        <button className="button-back" onClick={props.onClickButtonBack} />
        <button className="default-button button-clear" onClick={onClickButtonClear}>CLEAR</button>
        

     </div>

  );
}

export default withRouter(Filter);