import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import "../css/UserMenu.css";
import UserContext from "./context/UserContext";
import {useHistory,withRouter} from "react-router-dom";
import ClientContext from "./context/ClientContext";
import ConfirmContext from "./context/ConfirmContext";
import FavContext from "./context/FavContext";
import {apiConst} from "../constant/apiConst";
import AlertContext from "./context/AlertContext";

const UserMenu = (props) => {
    const { user, setUser } = useContext(UserContext);
    const { client, setClient } = useContext(ClientContext);
    const {confirm,setConfirm} = useContext(ConfirmContext);
    const {favs,setFavs} = useContext(FavContext);
    const {alert,setAlert} = useContext(AlertContext);
    const history = useHistory();

    function onClickLogout(){
        setConfirm({show:true,text:"You want to terminate session",onConfirm : onConfirm})
    }
    function onConfirm(){
        if(client) apiConst.presentationTerminate(localStorage.getItem("presentation_id"));
        setUser(null);
        setClient(null);
        setFavs([]);
        localStorage.removeItem("user");
        localStorage.removeItem("presentation_id");
        localStorage.removeItem("client");
        localStorage.removeItem("favs");
        history.push("/");
        if(client) setAlert("Presentation is terminated");
    }

    return (
        <div>
            <div className="sub-user-menu">
                <div className="d-flex justify-content-between">
                    <img src={require("../img/icon-sales.svg")} alt="" width={30}/>
                    <a className="red-logout-button" onClick={onClickLogout} />
                </div>
                <div className="mt-3">
                    <div className="user-menu-role">{user.role.name}</div>
                    <div className="user-menu-name">{user.name}</div>
                </div>
            </div>

            <Link to="/dashboard">
                <button className="default-button login" >DASHBOARD</button>
            </Link>


        </div>
    );
};

export default withRouter(UserMenu);