import React, {useContext, useEffect, useState} from 'react';
import '../../css/dashboard/Dashboard.css';
import DashboardHeader from './DashboardHeader.js';
import DashboardTable from './DashboardTable.js';
import NumberBox from './NumberBox.js';
import {LineChart, Line, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import {apiConst} from "../../constant/apiConst";
import UserContext from "../context/UserContext";
import AppDataContext from "../context/AppDataContext";

function DashboardGeneralHome() {
  const {user,setUser} = useContext(UserContext);
  const {appData,setAppData} = useContext(AppDataContext);
  const [chartType, setChartType] = useState(0);
  const [data1,setData1] = useState(null);
  const [dataMonthly,setDataMonthly] = useState(null);
  const [dataYearly,setDataYearly] = useState(null);
  const [totals,setTotals] = useState({revenue : -1, sold : -1, option : -1, client : -1})
  const [chartPie,setChartPie] = useState([
    {name: 'Sold', value: 33.3},
    {name: 'Option', value: 33.3},
    {name: 'Other', value: 33.3},
  ])

  const COLORS = ['#007581', '#61bac6', '#addfe5'];


  useEffect(() => {

    apiConst.getRecentSells().then(sells => {
      let data_1 = {
        className: "recent-sells",
        title: "Recent Sells",
        headings: ["Property", "Client", "Sales Rep"],
        rows: []
      };
      sells.forEach(sell => {
        data_1.rows.push({
          property: {id: sell.property.id, name: sell.property.code},
          client: {id: sell.client.id, name: sell.client.first_name +" "+sell.client.last_name},
          salesRep: {id: sell.user.id, name: sell.user.name}
        })
      })
      setData1(data_1);
    })

    apiConst.getRevenueByMonthAndYear().then(data => {
      let chartMonthlyData = [];
      data.monthly.forEach(month => {
        chartMonthlyData.push({name:month[0],revenue:month[1]})
      })
      setDataMonthly(chartMonthlyData);

      let chartYearlyData = [];
      data.yearly.forEach(year => {
        chartYearlyData.push({name:year[0],revenue:year[1]})
      })
      setDataYearly(chartYearlyData);
    });


  },[])

  useEffect(() => {
    if(appData.properties)
    {
      apiConst.getUser(user.id).then(user => {

        setTotals({
          revenue : user.totals.revenue,
          sold : user.totals.sold,
          option : user.totals.option,
          client : user.totals.client
        })

        let sold = Math.ceil((user.totals.sold / appData.properties.length)*100);
        let option = Math.ceil((user.totals.option / appData.properties.length)*100);
        let other = 100 - sold - option;
        setChartPie([
          {name: 'Sold', value: sold},
          {name: 'Option', value: option},
          {name: 'Other', value: other},
        ])

      })
    }
  },[appData])

  return (

    <section className="primary dashboard home manager">
      
      <DashboardHeader slug="home" />
  
      <div className="container">
        
        <div className="row">
          
          <div className="col-2">

            <NumberBox className="number-box" number={totals.revenue} text="Revenue (M)" />
            <NumberBox className="number-box" number={totals.sold} text="Sold" />
            <NumberBox className="number-box" number={totals.option} text="Option" />
            <NumberBox className="number-box" number={totals.client} text="Client" />

          </div>


          <div className="col-10">

            <div className="row">
              <div className="col-12">

                <div className="chart-container">

                  <div className="header">
                    <h3>Revenue (£)</h3>
                    
                    <select className="form-control" onChange={(e)=>{
                      
                      setChartType(parseInt(e.target.value));
                      
                    }}>
                      <option value="0">Monthly</option>
                      <option value="1">Yearly</option>
                    </select>

                  </div>


                  {chartType === 0 && (
                    <ResponsiveContainer width="100%" height="80%">
                      <LineChart data={dataMonthly}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis unit="M" />
                        <Tooltip />
                        
                        <Line dataKey="revenue" stroke="#007581" strokeWidth="2" dot={{ r: 6 }} />
                      </LineChart>
                    </ResponsiveContainer>
                  )}

                  {chartType === 1 && (
                    <ResponsiveContainer width="100%" height="80%">
                      <LineChart data={dataYearly}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis unit="M" />
                        <Tooltip />
                        
                        <Line dataKey="revenue" stroke="#007581" strokeWidth="2" dot={{ r: 6 }} />
                      </LineChart>
                    </ResponsiveContainer>
                  )}

                  
                </div>

              </div>
            </div>

            <div className="row">
              <div className="col-5">
                
                <div className="chart-container pie-chart">
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={chartPie}
                        dataKey="value"
                        innerRadius="75%"
                        outerRadius="80%" 
                        fill="#0f0"
                        paddingAngle={5}
                        label={true}
                        labelLine={true}
                      >
                        {
                          chartPie.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
                        }
                      </Pie>
                      <Legend iconType="circle" layout="vertical" align="center" verticalAlign="middle" />
                    </PieChart>
                  </ResponsiveContainer>
                </div>

                    
                    

              </div>

              <div className="col-7">
                <DashboardTable data={data1} />
              </div>
            </div>
            
         </div>
          
        </div>
      </div>
    </section>

  );
  
}

export default DashboardGeneralHome;