import React, {useState} from 'react';
import "../css/FavButton.css";


import Preloader from "./Preloader";

const FavButton = ({isFav,isWaiting,onClick}) => {


    return (
        <div className={isFav ? "fav-button is-fav" : "fav-button"} onClick={onClick}>
            { !isWaiting && <div className="icon-like" /> }
            { isWaiting && <Preloader /> }
        </div>
    );
};

export default FavButton;