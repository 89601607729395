import React from 'react';
import {Link} from "react-router-dom";

const SubMenuItem = ({title,link,isLast}) => {
    let className = "submenu-item"
    className += isLast ? " last" : "";
    return (
        <div>
            <Link to={link} className={className}>{title}</Link>
        </div>


    );
};

export default SubMenuItem;