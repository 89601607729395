import React, { useState, useEffect, useContext } from 'react';
import UserContext from "./context/UserContext.js";
import {apiConst} from "../constant/apiConst";
import ButtonWithLoading from "./ButtonWithLoading";
import AlertContext from "./context/AlertContext";
import {Link,useParams,useHistory} from "react-router-dom";
import ClientContext from "./context/ClientContext";
import FavContext from "./context/FavContext";



function ClientInvitationWait() {

  const {id} = useParams();
  const [fields, setFields] = useState({code:""});

  const { alert, setAlert } = useContext(AlertContext);
  const { client, setClient } = useContext(ClientContext);
  const { user, setUser } = useContext(UserContext);
  const { favs, setFavs } = useContext(FavContext);
  const history = useHistory();

  const [ minutes, setMinutes ] = useState(1);
  const [seconds, setSeconds ] =  useState(59);

  useEffect(()=>{
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000)
    return ()=> {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if(client)
    {

      setFavs(client.favs);
      localStorage.setItem("favs",JSON.stringify(client.favs));

      apiConst.presentationCreate(client.user.id,client.id).then(result => {
        if(result.response)
        {
          localStorage.setItem("client",JSON.stringify(client));
          localStorage.setItem("presentation_id",result.presentation.id);
          setAlert("Hello " + client.first_name + " " + client.last_name);
          history.push("/");

        }
      })
    }

  },[client]);

  useEffect(() => {
    if(user)
    {
      localStorage.removeItem("user");
      localStorage.removeItem("presentation_id");
      localStorage.removeItem("client");
      localStorage.removeItem("favs");
      localStorage.setItem("user",JSON.stringify(user));
    }

  },[user]);

  useEffect(()=> {
    let interval = setInterval(checkInvitation,5*1000);
    return ()=> {
      clearInterval(interval);
    };
  },[])


  function checkInvitation()
  {


      apiConst.clientInvitationCheck(id).then(result => {

        if(result.response)
        {
          setUser(result.user);
          setClient(result.client);
        }
        else {



        }
      })

    }






  return (
    <section className="sign-section">
    <div className="sign-wrap">

        <div className="inner">
          <h2>WAITING CLIENT</h2>
          <p>Please wait for client accept your intivation</p>
          <div className={"text-center"}>
            { minutes === 0 && seconds === 0
                ? null
                : <h1> {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</h1>
            }
          </div>
          <div className="mt-4 ">
            <div className="seperator-text">
              OR
            </div>
          </div>
          <div className="mt-4">
            <Link className={"default-button lined login"} to="/inviteClient">GO BACK</Link>
          </div>



        </div>




    </div>
    </section>
  );
}

export default ClientInvitationWait;